import React, { ReactElement, useEffect, useState } from 'react'
import {
  AsyncButton,
  Button,
  ButtonStyle,
  GiftCardStatus,
  hasRole,
  Order,
  Role,
  Table,
} from '@one-tree/library'
import download from 'downloadjs'
import { useHistory } from 'react-router-dom'
import Card, { CardSize } from '../../components/page/Card'
import CardContent from '../../components/page/CardContent'
import CardHeader from '../../components/page/CardHeader'
import { useOrganisation } from '../../context/OrganisationProvider'
import { exportGiftCards, searchGiftCards } from '../../helpers/APIHelper'
import { capitalize } from '../../helpers/DataTransformer'
import {
  salesColumns,
  salesFilters,
  SalesTableState,
} from '../../helpers/TableHelper'
import Page from '../../hoc/Page'
import { RoutePath } from '../../types/Routes'
import { getCSVExportTitle } from '../reporting/ReportingHelper'
import QuickStats from './QuickStats'

export default function Sales(): ReactElement {
  const { organisation, organisationUser } = useOrganisation()

  const [tableState, setTableState] = useState<SalesTableState>()

  const [voucherIdList, setVoucherIdList] = useState<string[]>()
  useEffect(() => {
    if (voucherIdList) {
      const stringList = JSON.stringify(voucherIdList)
      sessionStorage.setItem('voucherIdList', stringList)
    }
  }, [voucherIdList])

  const {
    fromDate, toDate, search, status, orderBy, orderDirection,
  } = tableState || {}

  const history = useHistory()

  const fetchCSV = async (): Promise<void> => {
    const res = await exportGiftCards({
      search,
      orderBy,
      orderDirection,
      fromDate,
      toDate,
      status,
    })
    if (res) download(res, getCSVExportTitle(fromDate, toDate, capitalize(status)))
  }

  const userIsAdmin = organisationUser && hasRole(organisationUser.role, Role.OrganisationAdmin)

  return (
    <Page>
      <Card cardSize={CardSize.Full}>
        <CardHeader title="Sales">
          {userIsAdmin && (
            <AsyncButton onClick={fetchCSV}>Download CSV</AsyncButton>
          )}
          <Button
            buttonStyle={ButtonStyle.Action}
            onClick={(): void => history.push(RoutePath.ManualOrder)}
          >
            Manual order
          </Button>
        </CardHeader>
        <CardContent>
          {userIsAdmin && <QuickStats />}
          <Table
            fetch={searchGiftCards}
            columns={salesColumns(
              organisation,
              status === GiftCardStatus.Redeemed,
            )}
            options={{
              orderBy: 'purchaseDate',
              orderDirection: Order.Desc,
            }}
            search={true}
            filters={salesFilters}
            dates={{
              // To change to within the past year:
              // startDate: moment().subtract(1, 'year').add(1, 'day').unix(),
              // endDate: moment().unix(),
              startDate: undefined,
              endDate: undefined,
            }}
            rowLink={{ route: RoutePath.VoucherDetail, slug: 'id' }}
            getState={setTableState}
            getData={(data): void => {
              if (data) setVoucherIdList(data.map((row) => row.id))
            }}
          />
        </CardContent>
      </Card>
    </Page>
  )
}
